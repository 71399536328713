import React, { useContext, useEffect, useState, useRef } from "react"
import { graphql } from "gatsby"
import { Container, Image, Row, Col, Accordion, useAccordionToggle } from "react-bootstrap"
import AccordionContext from 'react-bootstrap/AccordionContext';
import { Controller, Scene } from 'react-scrollmagic';
import { Helmet } from "react-helmet";
import ReactHtmlParser from 'react-html-parser';
import Fade from 'react-reveal/Fade';
import config from 'react-reveal/globals';
import Ticker from 'react-ticker'

import Header from "../components/header"
import Footer from "../components/footer"
import Faces from "../components/faces"

import "../styles/index.scss"

import ourClients from "../images/our-clients/theo-and_our_clients@1x.png";
import andOurselvesA from "../images/and-ourselves/and-ourselves-A.png";

import englishCircle1 from "../images/circle-graphic/english/english_circle_1.svg";
import englishCircle2 from "../images/circle-graphic/english/english_circle_2.svg";
import englishCircle3 from "../images/circle-graphic/english/english_circle_3.svg";
import englishCircle4 from "../images/circle-graphic/english/english_circle_4.svg";

import englishDidntKnow from "../images/theo-is-people/theo-manifesto-huh_english.gif";
import frenchDidntKnow from "../images/theo-is-people/theo-manifesto-huh_french.gif";
import englishKids from "../images/theo-is-people/theo-manifesto-kids.gif";
import englishBosses from "../images/theo-is-people/theo-manifesto-bosses.gif";
import englishTraffic from "../images/theo-is-people/theo-manifesto-traffic.gif";
import englishHealth from "../images/theo-is-people/theo-manifesto-health.gif";
import englishMoney from "../images/theo-is-people/theo-manifesto-money.gif";
import questionBubble from "../images/theo-is-people/theo-manifesto-talking_to.gif";

import frenchCircle1 from "../images/circle-graphic/french/french_circle_1.svg";
import frenchCircle2 from "../images/circle-graphic/french/french_circle_2.svg";
import frenchCircle3 from "../images/circle-graphic/french/french_circle_3.svg";
import frenchCircle4 from "../images/circle-graphic/french/french_circle_4.svg";

import logo_theo from "../images/logos/theo-main.svg";
import logo_theo_1 from "../images/logos/theo-main-1.svg";
import logo_theo_2 from "../images/logos/theo-main-2.svg";
import logo_taxi from "../images/logos/theo-companies-taxi@2x.png";
import logo_mindshare from "../images/logos/theo-companies-mindshare@2x.png";
import logo_johnst from "../images/logos/theo-companies-johnst@2x.png";

import face_1 from "../images/faces/face-1.png";
import face_2 from "../images/faces/face-2.png";
import face_3 from "../images/faces/face-3.png";
import face_4 from "../images/faces/face-4.png";
import face_5 from "../images/faces/face-5.png";

const Home = ({ pathContext, data }) => {

  const [ scrollMobileUp, setScrollMobileUp ] = useState(false);
  const [ headerInvert, setHeaderInvert ] = useState(false);
  
  const app = useRef(null);

  const setBgDark = () => {
    document.querySelectorAll(".nav-element").forEach(el => {
      el.classList.remove("light");
      el.classList.add("dark");
    });
    document.querySelector(".chevron").classList.remove("light");
    document.querySelector(".chevron").classList.add("dark");

    document.querySelector("body").classList.remove("bg-wool");
    document.querySelector("body").classList.add("bg-black");

    app.current.classList.add('bg-dark');
    app.current.classList.add('text-light');
  }
  const setBgLight = () => {
    document.querySelectorAll(".nav-element").forEach(el => {
      el.classList.remove("dark");
      el.classList.add("light");
    });
    document.querySelector(".chevron").classList.remove("dark");
    document.querySelector(".chevron").classList.add("light");

    document.querySelector("body").classList.remove("bg-black");
    document.querySelector("body").classList.add("bg-wool");

    app.current.classList.remove('bg-dark');
    app.current.classList.remove('text-light');
  }

  const circles = {
    default: [englishCircle1, englishCircle2, englishCircle3, englishCircle4],
    allotheo: [frenchCircle1, frenchCircle2, frenchCircle3, frenchCircle4]
  }

  const strings = {
    default: {
      'openingsTitle' : 'Current openings',
      'openingsLabel' : 'Show me openings',
      'showLess' : 'show me less',
      'showMore' : 'show me more',
      'theoIsPeople': `
        <p>
          <em>Theo is people</em><span class="text-amber">*</span>. 
          And like all people, we want to do things and make things that we’re proud of. 
          Things that people take note of, talk about, like, and appreciate. 
          <br class="d-block d-lg-none"/>Ideas that make people laugh or cry or even just go 
          <img class="didnt-know" src=${ englishDidntKnow } />. 
          Ideas that make people act. We do that by speaking to people as people.`,
      'itsNotComplicated': `
        <p>
          It’s not complicated, but we can sometimes make it complicated. Or we can forget who we are actually talking to <img class="img-inline" src=${ questionBubble } />.
        <p>
          They are people who are just trying to make it through the day or the week, dealing with the challenges that life throws at them every day—
        <p>
          <div class="d-block d-lg-inline">Kids <img class="img-inline" src=${ englishKids } />.</div>
          <div class="d-block d-lg-inline">Bosses <img class="img-inline" src=${ englishBosses } />.</div>
          <div class="d-block d-lg-inline">Traffic <img class="img-inline" src=${ englishTraffic } />.</div>
          <div class="d-block d-lg-inline">Health <img class="img-inline" src=${ englishHealth } />.</div>
          <div class="d-block d-lg-inline">Money <img class="img-inline" src=${ englishMoney } />.</div>
        <p class="mt-5 mt-lg-0">
          That’s the real challenge. How do we get through all that and speak to them in a meaningful way?
        <p>
          That’s what the best ideas do.
        <p>
          <em>And that’s what we try to make.</em>
      `
    },
    allotheo: {
      'openingsTitle' : 'Postes à pourvoir',
      'openingsLabel' : 'Afficher les postes',
      'showLess' : 'afficher moins',
      'showMore' : 'afficher plus',
      'theoIsPeople': `
        <p><em>Theo, c’est les gens</em><span class="text-amber">*</span>. 
        Et comme tous les êtres humains, nous voulons faire et créer des choses dont nous sommes fiers. 
        Des choses que les gens vont remarquer, dont ils vont discuter et qu’ils apprécieront. 
        Des idées qui feront rire les gens, ou pleurer, ou encore, qui leur feront dire : 
        <img class="didnt-know" src=${ frenchDidntKnow } />. 
        Des idées qui les pousseront à agir.</p>`,
      'itsNotComplicated': `
        <p>
          Ce n’est pas sorcier. Mais nous — les agences, les clients, les consultants, nous tous — pouvons parfois rendre cela compliqué. Ou nous oublions à qui nous nous adressons <img class="img-inline" src=${ questionBubble } />.
        </p>
        <p>
          À ces gens qui essaient de boucler leur journée, ou leur semaine en affrontant les défis que la vie met en travers de leur chemin. 
        </p>
        <p>
          <div class="d-block d-lg-inline">Les enfants <img class="img-inline" src=${ englishKids } />,</div>
          <div class="d-block d-lg-inline">les patrons <img class="img-inline" src=${ englishBosses } />,</div>
          <div class="d-block d-lg-inline">les bouchons de circulation <img class="img-inline" src=${ englishTraffic } />,</div>
          <div class="d-block d-lg-inline">leur santé <img class="img-inline" src=${ englishHealth } />,</div>
          <div class="d-block d-lg-inline">le budget <img class="img-inline" src=${ englishMoney } />.</div>
        </p>
        <p class="mt-5 mt-lg-0">
          Voilà le véritable défi. Comment nous distinguer parmi tous ces enjeux et interpeller les gens de façon percutante? 
        </p>
        <p>
          Voilà ce que font les grandes idées. 
        </p>
        <p>
          <em>Et c’est ce que nous tentons de créer.</em>
        </p>
        `
    }
  }

  //set copy lang
  const copy = strings[ pathContext.locale ];

  const home = data.Gatsby.Homepage;

  config({ ssrFadeout: true });
  
  const data_whoWeAre = {
    headline: data.Gatsby.Homepage.whoWeAre_headline,
    copy: data.Gatsby.Homepage.whoWeAre_copy,
    johnStCopy: data.Gatsby.Homepage.whoWeAre_johnStCopy,
    mindshareCopy: data.Gatsby.Homepage.whoWeAre_mindshareCopy,
    taxiCopy: data.Gatsby.Homepage.whoWeAre_taxiCopy
  }
  const data_jobListings = {
    headline: data.Gatsby.Homepage.jobListings_headline,
    copy: data.Gatsby.Homepage.jobListings_copy,
    email: data.Gatsby.Homepage.jobListings_email
  }

  const data_jobs = data.Gatsby.entries;
  const data_categories = data.Gatsby.JobCategories;
  const data_locations = data.Gatsby.JobLocations;

  const allJobs = [];

  // Push "Everywhere" location
  allJobs.push({
    location: "Everywhere",
    categories: []
  });
  
  // Push every location
  data_locations.forEach((location, index) => {
    allJobs.push({
      location: location.title,
      categories: []
    });
  });

  // Push each category
  allJobs.forEach(el => {
    data_categories.forEach(category => {
      el.categories.push({
        category: category.title,
        jobs: []
      });
    });
  });

  // Add all jobs to corresponding arrays in allJobs
  data_jobs.forEach(job => {
    const currentJobLocation = job.job_location[0].title;
    const currentJobCategory = job.job_category[0].title;

    allJobs.forEach(location => {
      if(location.location === "Everywhere" || location.location === currentJobLocation) {
        location.categories.forEach(category => {
          if(currentJobCategory === category.category) {
            category.jobs.push(job);
          }
        });
      }
    });
  });

  function switchLocation(e) {
    // console.log(e.target.getAttribute("tab"));

    const tabContents = Array.from(document.getElementsByClassName("tabContent"));
    const tabButtons = Array.from(document.getElementsByClassName("tabButton"));
    const chosenIndex = parseInt(e.target.getAttribute("tab"));

    tabContents.forEach((tabContent, tab_index) => {
      // console.log(`${chosenIndex} - ${tab_index}`);
      // console.log(chosenIndex == tab_index);
      if(chosenIndex === tab_index) {
        tabContent.classList.remove("d-none");
        tabContent.classList.add("d-block");

        e.target.classList.remove("secondary");
        e.target.classList.add("primary");
      } else {
        tabContent.classList.remove("d-block");
        tabContent.classList.add("d-none");

        tabButtons[tab_index].classList.remove("primary");
        tabButtons[tab_index].classList.add("secondary");
      }  
    });
  }
  
  function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
  
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );
  
    const isCurrentEventKey = currentEventKey === eventKey;
  
    return (
      <div className="job-category d-lg-flex align-items-lg-center justify-content-lg-between" onClick={decoratedOnClick}>
        <p className="display-4 mb-1 mb-lg-0">
          {children}
        </p>
        <p className="body-s mb-lg-0">{isCurrentEventKey ? copy.showLess : copy.showMore }</p>
      </div>
    );
  }

  useEffect(() => {
    if (typeof window === 'undefined') return;
  
    const handleResize = () => { 
      if ( window.innerWidth < 768 ) {
        setScrollMobileUp(false);
      } else {
        setScrollMobileUp(true)
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize)
    };
  });

  const handleScroll = (e) => {
    // console.log(e);
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) { 
      console.log('fin');
    }
  }

  let faceAnimationPlayed = false;
  let faceAnimationTimeout;
  let canTransition = false;
  const playFaceAnimation = () => {
    const faces = Array.from(document.getElementsByClassName("logo-face"));
    const logo_o = document.getElementById("logo-o");
    logo_o.classList.remove("show");
    
    const queue = [...faces, logo_o];

    playQueue(queue);
  }

  const resetFaceAnimation = () => {
    // Stop animation in progress
    if(faceAnimationPlayed && faceAnimationTimeout != undefined) clearTimeout(faceAnimationTimeout);
    faceAnimationTimeout = undefined;

    // Reset states of images
    const faces = Array.from(document.getElementsByClassName("logo-face"));
    faces.forEach(el => {
      el.classList.remove("show");
    });
    const logo_o = document.getElementById("logo-o");
    logo_o.classList.add("show");

    faceAnimationPlayed = false;
  }

  const playQueue = (queue, index) => {
    let currentIndex = index != undefined ? index : 0;

    // Reset logo container
    const logo_container = document.getElementById("logo");
    logo_container.classList.remove("hide");

    faceAnimationTimeout = setTimeout(() => {
      if(currentIndex > 0) queue[currentIndex - 1].classList.remove("show")
      queue[currentIndex].classList.add("show");

      if(currentIndex != queue.length - 1) {
        playQueue(queue, currentIndex + 1);
      } else {
        // Hide Logo
        if(canTransition) {
          setTimeout(() => {
            document.getElementById("logo").classList.add("hide");
            // Jump down to next section
            setTimeout(() => {
              if(document.getElementById("all-of-us").offsetParent) {
                window.scroll({
                  top: document.getElementById("all-of-us").offsetParent.offsetTop + 100, 
                  left: 0
                });
              }
            }, 800);
          }, 800);
        }
      }
    }, currentIndex === 0 ? 0 : 320);
  }

  return (
    <div id="app" ref={app} className={`bg-wool w-100 bg-event`} style={{overflowX: "hidden"}} >
      <Helmet>
        <meta charSet="utf-8" />

        <meta property="og:title" content={ { "default": "Theo", "allotheo": "Theo" }[pathContext.locale] }/>
        <meta property="og:description" content={ { "default": "Who is Theo? Theo is all of us.", "allotheo": "Qui est Theo? Theo, c'est nous tous. " }[pathContext.locale] }/>
        <meta property="og:image" content="/meta/theo-http_social_share-thumbnail.jpg"/>
        <meta property="og:url" content={ { "default": "https://www.hellotheo.com/", "allotheo": "https://www.hellotheo.com/fr" }[pathContext.locale] }/>

        <meta name="twitter:title" content={ { "default": "Hello Theo", "allotheo": "Allo Theo" }[pathContext.locale] }/>
        <meta name="twitter:description" content={ { "default": "Who is Theo? Theo is all of us.", "allotheo": "Qui est Theo? Theo, c'est nous tous. " }[pathContext.locale] }/>
        <meta name="twitter:image" content="https://hellotheo.com/meta/theo-http_social_share-thumbnail.jpg"/>
        <meta name="twitter:card" content="summary_large_image"/>
        
        <title>{ { "default": "Hello Theo", "allotheo": "Allo Theo" }[pathContext.locale] }</title>
        <link href="https://webfonts2.radimpesko.com/RP-W-5eecd5246f3e0c18ae000006.css" rel="stylesheet" type="text/css" />
      </Helmet>

      <Header invert={ headerInvert } location={ pathContext.locale } />
      
      <>
        <Controller>
          
          <Scene duration={"300%"} triggerHook={0} pin={true}>
            {(progress, event) => {
              if (event.type === 'enter') {
                canTransition = true;
                setBgLight();
              } else if (faceAnimationPlayed && progress < 0.25) {
                resetFaceAnimation();
              }

              if (event.type === 'leave') {
                canTransition = false;
              }

              if(event.state === "DURING" && progress >= 0.25) {
                if(!faceAnimationPlayed) {
                  faceAnimationPlayed = true;
                  playFaceAnimation();
                }
              }
              
              return(
                <div className={`vw-100 vh-100`}>
                  <section id="circles-section" className={`position-absolute w-100 h-100`}>
                    <div className={`transition-1s ${progress < 0.25 ? "" : "clear"}`}>
                      <div className={`circle-graphics vw-100 vh-100 d-flex align-items-center justify-content-center`}>
                        { circles[ pathContext.locale ].map( (circle, index) => (
                          <Image 
                            key={index} 
                            alt="First circle" 
                            className={`circle-graphic position-absolute ${ index % 2 == 0 ? 'alt' : ''}`}
                            src={circle} 
                            fluid 
                          />
                        ))}
                      </div>
                    </div>
                  </section>

                  <section id="logo-section" className={`position-absolute`}>
                    <div className={`vw-100 vh-100 overflow-hidden`}>
                      <div className={`transition-1s ${progress >= 0.25 ? "" : "clear"}`}>
                        <div className={`vw-100 vh-100 d-flex align-items-center justify-content-center`}>
                          <div id="logo" className="d-flex align-items-center justify-content-center">
                            <Image alt="Theo Logo" className="logo-img-1" src={logo_theo_1} fluid />
                            <div className="logo-img-2 position-relative">
                              <Image className={`logo-face`} src={ face_1 } fluid />
                              <Image className={`logo-face`} src={ face_2 } fluid />
                              <Image className={`logo-face`} src={ face_3 } fluid />
                              <Image className={`logo-face`} src={ face_4 } fluid />
                              <Image className={`logo-face`} src={ face_5 } fluid />
                              <Image className={`logo-face`} src={ face_2 } fluid />
                              <Image className={`logo-face`} src={ face_3 } fluid />
                              <Image alt="Theo Logo" id="logo-o" className={`theo-logo`} src={logo_theo_2} fluid />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              );
            }}
          </Scene>

          {/* Theo is all of us SECTION */}
          <Scene duration={"500%"} triggerHook={0} pin={true}>
            {(progress, event) => {
              if (event.type === 'enter') {
                setBgLight();
              }

              return(
                <div id="all-of-us" className={`vw-100 vh-100`}>
                  
                  <Faces progress={ progress }/>

                  <div className="h-100 d-flex align-items-center">
                    <Container>
                      <Fade>
                        <Row>
                          <Col md={{ span: 9 }} className="mb-5">

                            <div className="m-inline">
                              <p className={`display-1 transition-1s ${progress > 0 ? "" : "clear"}`}>
                                { home.allOfUsPrimary }
                              </p>
                              <div className={`transition-1s ${progress > 0.15 ? "" : "clear"}`}>
                                <p className="display-1">{ home.allOfUsSecondary }</p>
                              </div>
                            </div>

                            <div className={`transition-1s ${progress > 0.3 ? "" : "clear"}`}>
                              <p className="body-l">{ home.allOfUsBody }</p>
                            </div>
                          </Col>
                        </Row>
                      </Fade>
                    </Container>
                  </div>
                </div>
              );
            }}
          </Scene>

          {/* Our Clients SECTION */}
          <Scene duration={"200%"} triggerHook={0} pin={true}>
            {(progress, event) => {
              if (event.type === 'enter') {
                setBgLight();
              }

              return(
                <div className={`vw-100 vh-100`}>
                  <div className="h-100 d-flex align-items-center">
                    
                    <Container>
                      <Fade>
                        <Row className="align-items-center">
                          <Col md={{ span: 7 }}>
                            <p className={`display-1 transition-1s ${progress > 0 ? "" : "clear"}`}>
                              { home.ourClientsPrimary }
                            </p>                            
                            <div className={`transition-1s ${progress > 0.4 ? "" : "clear"}`}>
                              <p className="body-l">
                                { home.ourClientsBody }
                              </p>
                            </div>
                          </Col>
                          <Col className="d-none d-lg-block" lg={{ span: 5 }}>
                            <img className={`img-fluid transition-1s ${progress > 0.4 ? "" : "clear"}`} src={ourClients}/>
                          </Col>
                        </Row>
                      </Fade>
                    </Container>
                  </div>
                </div>
              );
            }}
          </Scene>

          {/* Our Selves SECTION */}
          <Scene duration={"200%"} triggerHook={0} pin={true}>
            {(progress, event) => {
              if (event.type === 'enter') {
                setBgLight();
              }

              return(
                <div className={`vw-100 vh-100`}>
                  <div className="h-100 d-flex align-items-center">
                    <Container>
                      <Fade>
                        <Row className="align-items-center">
                          <Col lg={{ span: 7 }}>
                            <p className={`display-1 transition-1s ${progress > 0 ? "" : "clear"}`}>
                              { home.ourselvesPrimary }
                            </p>

                            <div className={`transition-1s ${progress > 0.4 ? "" : "clear"}`}>
                              <p className="body-l">
                                { home.ourselvesBody }
                              </p>
                            </div>
                          </Col>
                          <Col className="d-none d-lg-block" lg={{ span: 5 }}>
                            <img className={`img-fluid transition-1s ${progress > 0.4 ? "" : "clear"}`} src={andOurselvesA}/>
                          </Col>
                        </Row>
                      </Fade>
                    </Container>
                  </div>
                </div>
              );
            }}
          </Scene>


          {/* WHO WE ARE SECTION */}
          <Scene duration={"250%"} triggerHook={0} pin={scrollMobileUp}>
            {(progress, event) => {
              // console.log('who-we-are ' + event.type);
              if (event.type === 'enter') {
                setBgLight();
              }

              return(

              <section id="who-we-are" className={`transition-1s ${progress > 0 && progress < 1.0 ? "" : "clear"}`}>
                <div className="py-5 py-md-0 vh-md-100 d-flex align-items-center">
                  <Container>
                    <Row>
                      <Col lg={{ span: 8, offset: 2 }} className="text-center mb-5">
                        <p className="display-3">{data_whoWeAre.headline}</p>
                        <p className="body-r">{data_whoWeAre.copy}</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={4} className="text-center pr-lg-4 mb-4 mb-lg-0">
                        <Fade>
                          <Image alt="Taxi Logo" className="who-we-are-logo mb-2 mb-lg-5" src={logo_taxi} fluid />
                          <p className="body-r">{data_whoWeAre.taxiCopy}</p>
                        </Fade>
                      </Col>
                        <Col lg={4} className="text-center px-lg-4 mb-4 mb-lg-0">
                          <Fade>
                            <Image alt="Mindshare Logo" className="who-we-are-logo mb-2 mb-lg-5" src={logo_mindshare} fluid />
                            <p className="body-r">{data_whoWeAre.mindshareCopy}</p>
                          </Fade>
                        </Col>
                        <Col lg={4} className="text-center pl-lg-4 mb-4 mb-lg-0">
                          <Fade>
                            <Image alt="john st. Logo" className="who-we-are-logo mb-2 mb-lg-5" src={logo_johnst} fluid />
                            <p className="body-r">{data_whoWeAre.johnStCopy}</p>
                          </Fade>
                        </Col>
                    </Row>
                  </Container>
                </div>
              </section>
              );
            }}
          </Scene>

          {/* Theo Is People SECTION */}
          <Scene duration={"350%"} triggerHook={0} pin={scrollMobileUp}>
            {(progress, event) => {
              
              if (event.type === 'enter') {
                setBgDark();
              } else if (event.scrollDirection === 'REVERSE' && event.type === 'leave') {
                setBgLight();
              }
              // else if (event.type === 'leave') {
              //   setBg('');
              // }

              // console.log(event.type);
              return(
                <section id="theo-is-people" className={`transition-1s ${progress > 0.1 && progress < 0.9 ? "" : "clear"}`}>
                  
                  <div className={`vh-md-100 pt-5 d-flex align-items-center`}>
                    <Container className={`pt-10 pt-md-0 `}>
                      <Row>
                        <Col>
                          <div className="img-25 display-2 mb-0" dangerouslySetInnerHTML={{__html: copy.theoIsPeople }} />
                        </Col>
                      </Row>
                    </Container>
                  </div>

                  <Ticker offset="-100">
                    {({ index }) => (
                      <>
                        <p className={`ticker-content show text-amber pr-5`}>{ {"default": `* Theo is derived from the Old Germanic THEUD, meaning “people” or “folk”.`, "allotheo": `* Theo est issu du mot theud d’origine germanique, qui signifie « peuple ».`}[pathContext.locale] }</p>
                      </> 
                    )}
                  </Ticker>
                  
                   
                </section>
              );
            }}
          </Scene>

          {/* It's not complicated SECTION */}
          <Scene duration={"550%"} triggerHook={0} pin={false}>
            {(progress, event) => {
              if (event.scrollDirection === 'REVERSE' && event.type === 'leave') {
                setBgDark();
              }
              return(
                <section id="its-not-complicated" className={`pb-10 transition-1s ${progress > 0 && progress < 1 ? "" : "clear"}`}>
                  <div className={`d-flex align-items-center`}>

                    <Container>
                      <Row>
                        <Col>
                          <div className="display-2 mb-0" dangerouslySetInnerHTML={{__html: copy.itsNotComplicated }} />
                        </Col>
                      </Row>
                    </Container>
                  </div>

                </section>
              );
            }}
          </Scene>

          {/* JOB LISTINGS SECTION */}
          <Scene duration={"100%"} triggerHook={.75} pin={false}>
            {(progress, event) => {
              // console.log(progress);
              if (event.scrollDirection === 'FORWARD' && event.type === 'enter') {
                setBgLight();
              } else if (event.scrollDirection === 'REVERSE' && event.type === 'leave') {
                setBgDark();
              }
              return (
                <section id="jobs-section" className="mt-10 py-10">
                  <Container>
                    <Fade>
                      <Row>
                        <Col lg={7}>
                          <p className="display-2">{data_jobListings.headline}</p>
                          <div className="body-l mb-5">{ ReactHtmlParser (data_jobListings.copy) }</div>
                          <a className="job-email-link" href={`mailto:${data_jobListings.email}`}><p className="body-l-link mb-6">{data_jobListings.email}</p></a>
                        </Col>
                      </Row>

                      <div id="job-listings">
                        <div className="jobs-menu px-0 px-lg-3 py-0 py-lg-2 mb-4 d-lg-flex align-items-center justify-content-between">
                          <div>
                            <p className="body-l mb-2 mb-lg-0">{ copy.openingsTitle }</p>
                          </div>
                          <div className="d-lg-flex align-items-center justify-content-between">
                            <p className="body-s mb-2 mb-lg-0 pr-0 pr-lg-1">{ copy.openingsLabel }</p>
                            {
                              allJobs.map((el, index) => (
                                <div onClick={switchLocation} tab={index} className={`mb-1 mb-lg-0 tabButton ${index === 0 ? "btn light primary cta-label" : "btn light secondary cta-label"} ${index === allJobs.length - 1 ? "" : "mr-1"}`} key={`job-nav-${el.location}`}>
                                  { pathContext.locale === "allotheo" && el.location === "Everywhere" ? "Partout" : el.location }
                                </div>
                              ))
                            }
                          </div>
                          <hr className="d-block d-lg-none"/>
                        </div>

                        {
                          allJobs.map((el, location_index) => (
                            <div className={`tabContent px-0 px-lg-3 ${el.location === "Everywhere" ? "d-block" : "d-none"}`} key={`${el.location}`}>
                              {
                                el.categories.map((category, category_index) => (
                                  category.jobs.length ?
                                  (<Accordion className="mb-5" defaultActiveKey={category_index < 2 ? "0" : ""} key={`${el.location} - ${category.category}`}>
                                    <ContextAwareToggle eventKey="0">{ category.category } <span className="display-4 italic">({ category.jobs.length })</span></ContextAwareToggle>
                                    <hr/>
                                    <Accordion.Collapse eventKey="0">
                                      <div>
                                        {
                                          category.jobs.map(job => (
                                            <a href={job.job_link} target="_blank" rel="noreferrer" className="job my-2" key={`${el.location} - ${category.category} - ${job.title}`}>
                                              <p className="body-l mb-1 job-title">{job.title}</p>
                                              <p className="body-s job-location">{job.job_location[0].title}</p>
                                            </a>
                                          ))
                                        }
                                      </div>
                                    </Accordion.Collapse>
                                  </Accordion>) : ""
                                ))
                              }
                            </div>
                          ))
                        }
                      </div>
                    </Fade>
                  </Container>
                </section>
               ) } }
          </Scene>
        </Controller>
      </>

      <Footer location={ pathContext.locale } onScroll={ handleScroll }/>
    </div>
  );
}

export const query = graphql`
  query ($locale: [String]) {
    Gatsby {
      Homepage: entry(section:"homepage", site: $locale) {
        ... on CraftAPI_homepage_homepage_Entry {
          jobListings_headline,
          jobListings_copy,
          jobListings_email,
          whoWeAre_headline,
          whoWeAre_copy,
          whoWeAre_johnStCopy,
          whoWeAre_mindshareCopy
          whoWeAre_taxiCopy
          allOfUsPrimary
          allOfUsSecondary
          allOfUsBody
          ourClientsPrimary
          ourClientsBody
          ourselvesPrimary
          ourselvesBody
          theoIsPeople
          itsNotComplicated
        }
      }
      entries(section:"jobs", site: $locale) {
        title,
        ... on CraftAPI_jobs_jobs_Entry {
          job_category {
            title
          },
          job_location {
            title
          },
          job_link
        }
      }
      JobCategories: categories(group: "job_categories", site: $locale) {
        title
      }
      JobLocations: categories(group: "job_locations", site: $locale) {
        title
      }
    }
  }
`;

export default Home;