import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap";
import footer_logo_pt_1 from "../images/logos/theo-footer-1-01.svg";
import footer_logo_pt_2 from "../images/logos/theo-footer-2-02.svg";

const Footer = (props) =>  {

  const locale = props.location === "default" ? "Toronto" : "Montreal";
  const [rotate, setRotate] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const scrollCheck = () => {
      
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        setRotate(true);
      } else {
        setRotate(false);
      }
    }
    window.addEventListener('scroll', scrollCheck);

    return () => {
      window.removeEventListener('scroll', scrollCheck)
    };
  }); 

  return (
    <div className="p-lg-4">
      <div className="footer p-2 p-lg-0">
        <Container className="px-lg-5 py-2 py-lg-3">
          <Row className="align-items-end justify-content-between">
            <Col xs={{ span: 12 }} lg={{ span: 6 }}>
              <div className="d-lg-flex align-items-lg-end mb-4 mb-lg-0">
                <div className="footer-logo d-flex align-items-baseline">
                  <img className="footer-logo-pt-1" src={footer_logo_pt_1} />
                  <img className={`footer-logo-pt-2 ${ rotate ? 'rotate' : ''}`} src={footer_logo_pt_2} />
                </div>
                <p className="body-s mb-0 pl-lg-2 colophon">{{"Toronto" : `This isn't “goodbye”, it's more of a “see you later”.`, "Montreal" : `Ce n’est pas un « au revoir ». C’est plutôt un « à bientôt »`}[locale]}</p>
              </div>
            </Col>
            <Col xs={{ span: 12 }} lg={{ span: 6 }} className="text-left text-lg-right">
              <p className="body-s mb-0 copyright">{{"Toronto" : `© 2020 Hello Theo, all rights reserved.`, "Montreal" : `© 2020 Hello Theo. Tous droits réservés.`}[locale]}</p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Footer;