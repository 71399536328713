import React, { useState } from "react"
import { Container, Row, Dropdown } from "react-bootstrap";
import Ticker from 'react-ticker'
import SVG from 'react-inlinesvg';
import Fade from 'react-reveal/Fade';

import DownChevron from "../images/icons/chevron_down.svg";

let locale,
    currentTime,
    currentWeather;

// Toronto Weather API
// http://api.openweathermap.org/data/2.5/weather?id=6167865&appid=6818f7e61d6f1b4051935c405c6d69ab

// Montreal Weather API
// http://api.openweathermap.org/data/2.5/weather?id=6077243&appid=6818f7e61d6f1b4051935c405c6d69ab

// EST Time API
// http://worldtimeapi.org/api/timezone/America/Toronto

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
  return (
    <a href="" ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }}>
      {React.Children.toArray(children)}
    </a>
  );
});

const CustomMenu = React.forwardRef(({ children, style, className }, ref) => {
    const [value, setValue] = useState('');
    
    return (
      <div ref={ref} style={style} className={className}>
        {React.Children.toArray(children)}
      </div>
    );
  },
);

const GetTickerContent = (props) => {
  if(props.weatherOrTime === 0) {
    if(currentWeather !== undefined) {
      return(<p className="ticker-content show body-r mx-4 mb-0">{`Expect a high of ${currentWeather.high}° and ${currentWeather.description} in ${locale}.`}</p>);
    } else if(currentTime !== undefined) {
      return(<p className="ticker-content show body-r mx-4 mb-0">{{"Toronto" : `The time is ${currentTime.time} in ${locale}.`, "Montreal" : `Il est présentement ${currentTime.time} à ${locale}.`}[locale]}</p>);
    }
  } else if(props.weatherOrTime === 1) {
    if(currentTime !== undefined) {
      return(<p className="ticker-content show body-r mx-4 mb-0">{{"Toronto" : `The time is ${currentTime.time} in ${locale}.`, "Montreal" : `Il est présentement ${currentTime.time} à ${locale}.`}[locale]}</p>);
    } else if(currentWeather !== undefined) {
      return(<p className="ticker-content show body-r mx-4 mb-0">{`Expect a high of ${currentWeather.high}° and ${currentWeather.description} in ${locale}.`}</p>);
    }
  }

  return (
    // Hidden placeholder content. Nobody will see this
    <p style={{ visibility: "hidden" }} className="ticker-content body-r mb-0">Null</p>
  );
};

class Header extends React.Component {
  constructor(props, context) {
    super(props, context);
    locale = this.props.location === "default" ? "Toronto" : "Montreal";
    this.state = {};
  }

  componentDidMount() {
    this.fetchTime();
    this.fetchWeather();

    this.setState({weatherOrTime: Math.round(Math.random())});

    if( locale === "Montreal") this.setState({weatherOrTime: 1});
  }

  fetchTime() {
    const estTimeURL = `https://worldtimeapi.org/api/timezone/America/Toronto`;
    fetch(estTimeURL)
      .then(res => res.json())
      .then(data => {
        const date = new Date(data.datetime);
        let hours = date.getHours(); // gives the value in 24 hours format
        let AmOrPm = hours >= 12 ? 'PM' : 'AM';
        hours = (hours % 12) || 12;
        let minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`;
        let finalTime = `${hours}:${minutes}${AmOrPm}`;

        currentTime = {
          type: "Time",
          time: finalTime
        };
        this.setState({timeContent: {"Toronto" : `The time is ${currentTime.time} in ${locale}.`, "Montreal" : `Il est présentement ${currentTime.time} à ${locale}.`}[locale]});
      });
  }

  fetchWeather() {
    const montrealWeatherURL = `https://api.openweathermap.org/data/2.5/weather?id=6077243&appid=6818f7e61d6f1b4051935c405c6d69ab&units=metric`;
    const torontoWeatherURL = `https://api.openweathermap.org/data/2.5/weather?id=6167865&appid=6818f7e61d6f1b4051935c405c6d69ab&units=metric`;
    fetch(locale === "Toronto" ? torontoWeatherURL : montrealWeatherURL)
      .then(res => res.json())
      .then(data => {
        currentWeather = {
          type: "Weather",
          high: Math.round(data.main.temp_max),
          description: data.weather[0].description
        };
        this.setState({weatherContent: `Expect a high of ${currentWeather.high}° and ${currentWeather.description} in ${locale}.`});
      });
  }

  render() {
    return (
      <Container fluid className="header py-2 px-3 px-lg-5 position-fixed">
        <Row className="align-items-center justify-content-between">
          <div>
            <div className="d-block d-lg-none">
              <Dropdown>
                <Dropdown.Toggle as={CustomToggle}>
                  <div className={`btn light primary cta-label nav-element`}>
                    {{"Toronto" : "Hello Theo", "Montreal" : "Allo Theo"}[locale]}
                    <SVG className={`chevron light`} src={DownChevron} />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu as={CustomMenu} className="dropdown-content">
                  {
                    locale === "Toronto" ? (
                      <a href="/fr" className="pl-lg-1">
                        <div className={`btn light secondary cta-label nav-element`}>
                          Allo Theo
                        </div>
                      </a>
                    ) : (
                      <a href="/" className="pl-lg-1">
                        <div className={`btn light secondary cta-label nav-element`}>
                          Hello Theo
                        </div>
                      </a>
                    )
                  }
                </Dropdown.Menu>
              </Dropdown>
            </div>

            {
              locale === "Toronto" ? (
                <div className="d-none d-lg-block">
                  <a href="/">
                    <div className={`btn light primary cta-label nav-element`}>
                      Hello Theo
                    </div>
                  </a>
                  <a href="/fr" className="d-none d-lg-inline-block pl-lg-1">
                    <div className={`btn light secondary cta-label nav-element`}>
                      Allo Theo
                    </div>
                  </a>
                </div>) : (
                <div className="d-none d-lg-block">
                  <a href="/">
                    <div className={`btn light secondary cta-label nav-element`}>
                      Hello Theo
                    </div>
                  </a>
                  <a href="/fr" className="d-none d-lg-inline-block pl-lg-1">
                    <div className={`btn light primary cta-label nav-element`}>
                      Allo Theo
                    </div>
                  </a>
                </div>
              )
            }
          </div>
          <Fade>
            <div>
              <div className="d-block d-lg-none">
                <Ticker offset="run-in" speed={4}>
                  {() => <GetTickerContent weatherOrTime={this.state.weatherOrTime} />}
                </Ticker>
              </div>
              <div className="d-none d-lg-block">
                {
                  this.state.weatherOrTime === 0 ? 
                  <p className={`ticker-content ${this.state.weatherContent !== undefined ? "show" : ""} body-r mb-0`}>{this.state.weatherContent}</p> : 
                  <p className={`ticker-content ${this.state.timeContent !== undefined ? "show" : ""} body-r mb-0`}>{this.state.timeContent}</p>
                }
              </div>
            </div>
          </Fade>
        </Row>
      </Container>
    )
  }
}

export default Header;