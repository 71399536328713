import React, { useEffect, useState } from "react"

const Faces = ({progress}) => {
  const facesCount = 25;
  const faces = 24;
  const [facesArray, setFacesArray] = useState([]);

  useEffect(() => {
    //generate faces
    for (var i = 0; i < facesCount; i++) {
      const width = 8;
      const face = Math.floor(Math.random() * faces);
      const xPos = Math.floor(Math.random() * 95) + 1;
      const yPos = Math.floor(Math.random() * 95) + 1;
      let x = (face) * width,
          y = 0;

      // if ( face > 7 ) {
      //   y = width;
      //   x = ((face) - 8) * width;
      // }

      y = Math.floor(face / 8) * width;
      x = (face % 8) * width;

      const faceObj = {
        face: face,
        x: -1*x,
        y: -1*y,
        xPos: xPos,
        yPos: yPos        
      }

      setFacesArray( prevState => [...prevState, faceObj]);
      // facesArray.push();
    }
  }, [])

  return (
    <div className="faces position-absolute vw-100 vh-100">
      { facesArray.map( (face, index) => {
        
        return (
          <div
            key={`face-${index}`}
            id={`face-${index}-${face.face}`}
            className={`face transition-1s ${progress > ( .3 + .5*(index / facesCount) ) ? "" : "clear"}`}
            style={
              {
                backgroundPosition: `${face.x}vw ${face.y}vw`,
                top: face.xPos + '%',
                left: face.yPos + '%'
              }
            }
          />
        )
      })}
    </div>
  )
}

export default Faces